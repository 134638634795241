<template>
    <div class="global-app-wrapper">
        <div class="app-external-wrapper uk-animation-fade" dir="rtl" style="direction: rtl; z-index: 2;">

            <saffron
                    class="app-external-wrapper uk-animation-fade"
                    :use-meta="true" >

                <router-view v-slot="{ Component }" :key="$route.fullPath">
                    <component :is="Component"  uk-scrollspy="cls: uk-animation-fade;"/>
                </router-view>

            </saffron>
        </div>


    </div>

</template>

<script>
import SaffronComponent from '@/client/components/Saffron.vue';

export default {
    components: {
        Saffron: SaffronComponent
    },
    props : {
        'layout' : {
            type: String,
            default: 'default'
        },
        'forcedLayout' : {
            default: false,
            validator: (value) => (typeof value === 'string' || value === false)
       }
    },
    data() {
        return {

        }
    },
    methods: {

    },
    computed : {

    },
    mounted () {

    },
    created () {

    },

  }
</script>
<style lang="scss">
.global-app-wrapper {
    display: flex;
    flex-direction: column;
    flex: 100vh;
    min-height: 100vh;
}

.change-log-wrap {
    margin-top: auto;
}


</style>
